<template>
    <div class="course-ware-content" :native="false">
        <el-breadcrumb separator="/" class="course-breadcrumb">
            <el-breadcrumb-item>{{course_name}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="zhangName">{{zhangName}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{courseCrumbs}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="back-btn">
            <el-button type="primary" size="small" @click="goBack">返 回</el-button>
        </div>
        <el-scrollbar class="course-ware-list">
            <ProjectDetail v-if="typeId === 7" :platform="3" :projectId="item_id"></ProjectDetail>
            <PaperDetail v-if="typeId === 6" :paper_id="item_id"></PaperDetail>
        </el-scrollbar>
        <div v-if="typeId !== 7 && typeId !==6" class="single-data">
            <!--左侧工具-->
          <video v-if="resourceType === 'mp4'" :src="file_path" controls controlslist="nodownload" ref="videoRef" />
          <el-scrollbar v-else-if="resourceType === 'pdf'" v-else class="pdf-wrapper">
            <vue-office-pdf :src="file_path" style="width: 100%" />
          </el-scrollbar>
          <iframe v-else :src="file_path" width="100%" height="100%"
                  class="iframe-content"></iframe>
        </div>
        <ResourceDialog :dialogVisible="dialogVisible" :src="src" @closeDialog="closeDialog"></ResourceDialog>
    </div>
</template>

<script>
    import ResourceDialog from "../../components/ResourceDialog";
    import ProjectDetail from "../../teacherCourse/ProjectDetail";
    import PaperDetail from "../../teacherCourse/component/PaperDetail";
    import VueOfficePdf from '@vue-office/pdf'

    export default {
        name: "FeaturedDetail",
        props: ['nodeIndex', 'chapterIndex'],
        components: {
            ResourceDialog,
            ProjectDetail,
            PaperDetail,
          VueOfficePdf
        },
        data() {
            return {
                // className: this.$route.query. || '',
                //课件列表
                courseWareList: [],
                //面包屑
                course_name: '',
                zhangName: '',
                typeId: Number(this.$route.query.typeId),
                src: "",
                dialogVisible: false,
                item_id: this.$route.query.id,
                file_path: this.$route.query.file_path,
              resourceType: '',
            }
        },
        computed: {
            courseCrumbs() {
                let name = '';
                switch (this.typeId) {
                    case 1:
                        name = '课件';
                        break;
                    case 2:
                        name = '教材';
                        break;
                    case 3:
                        name = '微课';
                        break;
                    case 4:
                        name = '动画';
                        break;
                    case 5:
                        name = '配套材料';
                        break;
                    case 6:
                        name = '测试中心';
                        break;
                    case 7:
                        name = '实训中心';
                        break;
                }
                return name;
            }
        },
        mounted() {
            this.getCourseInfo();
          this.resourceType = this.$route.query.file_path.substring(this.$route.query.file_path.lastIndexOf('.') + 1)
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
            closeDialog(val) {
                this.src = '';
                this.dialogVisible = false
            },
            viewDetail(item) {
                this.src = item.file_preview_path;
                this.dialogVisible = true;
            },
            //获取课件列表
            getCourseInfo() {
                let params = {
                    id: this.$route.query.course_id
                };
                this.$shttp.axiosGetBy(this.$api.courseinfo, params, (res) => {
                    if (res.code === 200) {
                        this.course_name = res.data.course_name;
                        this.zhangName = res.data.course_content[this.chapterIndex].name;
                        this.jieName = res.data.course_content[this.chapterIndex].children[this.nodeIndex].name;
                    }
                }, (error) => {
                    console.log(error);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
video {
  max-width: 100%;
  max-height: 100%;
}
    ::v-deep .el-breadcrumb {
        padding-bottom: 20px;
    }

    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .back-btn {
            position: absolute;
            right: 0;
            top: -8px;
        }

        .course-ware-list {
            flex: 1;
            width: 100%;
            height: 1%;
            background-color: #fff;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .course-ware {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;

                .course-ware-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 16px 0;
                    cursor: pointer;
                    border-radius: 10px;

                    &:hover {
                        transform: scale(1.2);
                        transition: 1s;
                    }

                    .course-ware-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 107px;
                        height: 107px;
                    }

                    img.course-ware-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }

                    span.course-ware-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 107px;
                        text-align: center;
                    }
                }
            }
        }

        .single-data {
            width: 100%;
            height: 100%;
          background: #000;
          display: flex;
          overflow: hidden;
          .pdf-wrapper {
            ::v-deep .el-scrollbar__wrap {
              overflow-x: hidden;
            }
            ::v-deep .vue-office-pdf {
              .vue-office-pdf-wrapper {
                padding: 0!important;
              }
              canvas {
                width: 100%!important;
                height: initial!important;
              }
            }
          }
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
            background-color: #fff;

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                display: flex;
                padding: 6px;

                .module-item {
                    display: inline-block;
                    min-width: 60px;
                    min-height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 10px;
                    margin-right: 15px;
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 4px;
                color: #409EFF;
                font-size: 14px;
                line-height: 60px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
</style>